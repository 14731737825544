<template>
  <component :is="component" :data="data" v-if="component" />
</template>
<script>
export default {
  name: "dynamic-layout",
  props: ["name", "data"],
  data() {
    return {
      component: null
    };
  },
  computed: {
    loader() {
      if (!this.name) {
        return null;
      }
      return () => import(`../layouts/${this.name}`);
    }
  },
  methods: {
    load() {
      this.loader()
        .then(() => {
          this.component = () => this.loader();
        })
        .catch(() => {
          //this.component = () => import('./empty')
        });
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    name() {
      this.load();
    }
  }
};
</script>