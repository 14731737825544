<template>
  <div id="app" @keypress="keyPress($event)">
    <img title="internet bağlantısı yok" v-show="!closeClicked && offline" src="/images/bg/offline.jpg" alt="offline"
      class="offlineIcon" @click="closeClicked = true" />
    <img v-if="!model.mainTemplate" src="/images/bg/start.jpg" style="width: 100%; height: 100%; position: fixed" />
    <img v-if="!model.isAccountActive" src="/images/bg/account-inactive.jpg"
      style="width: 100%; height: 100%; position: fixed; z-index: 99" />

    <dynamic-layout v-if="model.isAccountActive && model.mainTemplate && model.standartLayout"
      :name="model.standartLayout"></dynamic-layout>
  </div>
</template>

<script>
import DynamicLayout from "../components/DynamicLayout";
import hub from "../hub";
import Vue from "vue";
import store from "../store/index";
import userManager from "../manager/modules/user-manager";
import jwtHelper from "../shared/jwt-helper";
export default {
  name: "app",
  components: {
    DynamicLayout,
  },
  data() {
    return {
      hubInstalled: false,
      justHidden: false,
      offline: false,
      closeClicked: false
    };
  },
  methods: {
    mouseMoveEvent() {
      try {
        var j;
        window.addEventListener("mousemove", function () {
          if (!this.justHidden) {
            this.justHidden = false;
            if (j) {
              clearTimeout(j);
            }
            document.querySelector("*").style.cursor = "default";
            j = setTimeout(() => {
              document.querySelector("*").style.cursor = "none";
              this.justHidden = true;
              setTimeout(() => {
                this.justHidden = false;
              }, 500);
            }, 1000);
          }
        });
      } catch (error) {
        window.console.log(error)
      }
    },
    startHubTimer() {
      try {
        var timer = setInterval(() => {
          if (navigator.onLine) {
            if (!this.hubInstalled) {
              hub.install(Vue);
              this.hubInstalled = true;
            }
            clearInterval(timer);
          }
        }, 10000);
      } catch (error) {
        window.console.log(error);
      }
    },
    startRefreshPageTimer() {
      try {
        setInterval(() => {
          if (!this.offline) hub.install(Vue);
        }, 1000 * 60 * 60 * 2);
      } catch (error) {
        window.console.log(error);
      }
    },
    updateConnectionStatus() {
      this.offline = !navigator.onLine;
    },
    onKeyUp() {
      try {
        document.addEventListener("keyup", async function (evt) {
          if (evt.ctrlKey && evt.key.toLowerCase() === "b") {
            window.console.log("Pressed ctrl+b");
            await userManager.logOut();
          }
        });
      } catch (error) {
        window.console.log(error);
      }
    },
  },
  created() {
    try {
      if (!localStorage.getItem("token")) {
        window.location.href = "/#/login";
        return;
      }
      localStorage.setItem("lastUserId", jwtHelper.getUserId());
      this.onKeyUp();
      //this.startRefreshPageTimer();
      this.offline = !navigator.onLine;

      if (this.offline) {
        this.startHubTimer();
        store.dispatch("loadStore");
      } else {
        if (!this.hubInstalled) {
          hub.install(Vue);
          this.hubInstalled = true;
        }
      }
      window.addEventListener("online", this.updateConnectionStatus);
      window.addEventListener("offline", this.updateConnectionStatus);
      this.mouseMoveEvent();
    } catch (error) {
      window.console.log(error);
    }
  },
  computed: {
    model() {
      return this.$store.state;
    },
  },
};
</script>
<style lang="scss" scoped>
h1.account-active {
  border: 1px solid red;
  height: 25px;
  position: fixed;
  z-index: 109999;
  width: 113px;
  background: blue;
}

.offlineIcon {
  position: fixed;
  z-index: 9999;
  top: 45px;
  left: 45px;
  border-radius: 5px;
}
</style>